<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="40"
      >
        <v-toolbar-title>Create Cost Center</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          to="/setup/user-defaults"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <editor
          :initial="dataItem"
          @data="save"
        ></editor>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      dataItem: {},
    };
  },
  methods: {
    save(data) {
      const url = "/cost-centers";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$router.push("/cost-accounting/cost-centres");
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>