<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          required
          label="Cost Center"
          v-model="record.PrcCode"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Name"
          v-model="record.PrcName"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.CCOwner"
          :items="Employees"
          :item-text="(item) => item.firstName + '  -  ' + item.lastName"
          item-value="id"
          label="Owner"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.DimCode"
          :items="dimensions"
          item-text="DimDesc"
          item-value="id"
          label="Dimension"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Valid From"
              readonly
              v-on="on"
              dense
              v-model="record.ValidFrom"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.ValidFrom"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              label="Valid To"
              readonly
              v-on="on"
              outlined
              v-model="record.ValidTo"
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.ValidTo"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          label="Active"
          required
          v-model="record.Active"
          :true-value="'Y'"
          :false-value="'N'"
        ></v-checkbox>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          required
          label="Ext Code"
          v-model="record.ExtRef"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(dataField, i) in userDefinedFields"
        v-bind:key="i"
        cols="3"
      >
        <v-text-field
          outlined
          dense
          v-if="
            dataField.FieldType == 'string' || dataField.FieldType == 'integer'
          "
          :type="dataField.FieldType == 'integer' ? 'number' : 'text'"
          :readonly="dataField.readonly == 'Y' ? true : false"
          :filled="dataField.readonly == 'Y' ? true : false"
          v-model="itemRecord[dataField.FieldName]"
          :label="dataField.FieldDescription"
        ></v-text-field>

        <v-checkbox
          v-if="dataField.FieldType == 'checkbox'"
          v-model="itemRecord[dataField.FieldName]"
          :label="dataField.FieldDescription"
          true-value="Y"
          false-value="N"
        ></v-checkbox>

        <DatePicker
          v-if="dataField.FieldType == 'date'"
          @date="setDate"
          :myDate="itemRecord[dataField.FieldName]"
          :title="`${dataField.FieldDescription}`"
        ></DatePicker>
        <v-autocomplete
          outlined
          dense
          v-show="dataField.FieldType == 'select'"
          v-model="itemRecord[dataField.FieldName]"
          :label="dataField.FieldDescription"
          :items="dataField.items"
          :item-text="(item) => item.Value"
          :item-value="(item) => item.Descr"
        ></v-autocomplete>
        <v-radio-group
          v-if="dataField.FieldType == 'radio'"
          v-model="itemRecord[dataField.FieldName]"
          row
        >
          <template v-slot:label>
            <div>
              <strong>{{ dataField.FieldDescription }}</strong>
            </div>
          </template>

          <v-radio label="Yes" value="Y"></v-radio>
          <v-radio label="No" value="N"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    userDefinedFields: [],
    record: {},
    itemRecord: {},
    drivers: [],
    Employees: [],
    loader: false,
    dimensions: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.record.udfs = this.itemRecord;
      this.loader = true;
      this.$emit("data", this.record);
    },
    getDimensions() {
      const self = this;
      this.$store
        .dispatch("get", `/dimensions`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.dimensions = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    getUserFields() {
      const self = this;
      this.$store
        .dispatch("get", `/user_fields?ObjType=304`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.userDefinedFields = res.ResponseData.HeaderUserFields;
            console.log(this.userDefinedFields);
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee-master-data`)
        .then((res) => {
          self.Employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDimensions();
    this.getEmployees();
    this.getUserFields();
  },
};
</script>